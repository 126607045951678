<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4"></div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">Add new blog</h3>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <base-alert type="danger" v-if="errText !== ''">
            <strong>Lỗi!</strong> {{ errText }}
          </base-alert>

          <!-- Form groups used in grid -->
          <div class="row">
            <div class="col-md-12">
              <base-input
                v-model="inputs.domain"
                :inputClasses="errors.get('domain') ? 'is-invalid' : ''"
                :errors="errors.get('domain') ? [errors.get('domain')] : []"
                maxlength="100"
                label="Blog Domain"
                placeholder="Domain name where the blog will be deployed (add www. when needed)."
              >
              </base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <base-input label="Blog Network">
                <el-select
                  v-model="inputs.network"
                  filterable
                  placeholder="Select blog network"
                >
                  <el-option
                    v-for="network in networks"
                    :key="network.id"
                    :label="network.title"
                    :value="network.id"
                  >
                  </el-option>
                </el-select>
              </base-input>
              <div
                v-if="errors.get('network')"
                class="invalid-feedback"
                :style="{ display: errors.get('network') ? 'block' : 'none' }"
              >
                {{ errors.get("network") }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <base-input
                v-model="inputs.title"
                :inputClasses="errors.get('title') ? 'is-invalid' : ''"
                :errors="errors.get('title') ? [errors.get('title')] : []"
                maxlength="100"
                label="Blog Title"
                placeholder="The WordPress 'Site Title'."
              >
              </base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <base-input label="Active theme">
                <el-select
                  v-model="inputs.theme"
                  filterable
                  placeholder="Choose a random theme"
                >
                  <el-option
                    v-for="theme in themeOptions"
                    :key="theme.value"
                    :label="theme.label"
                    :value="theme.value"
                  >
                  </el-option>
                </el-select>
              </base-input>
              <div
                v-if="errors.get('theme')"
                class="invalid-feedback"
                :style="{ display: errors.get('theme') ? 'block' : 'none' }"
              >
                {{ errors.get("theme") }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <base-input label="Install Popular Plugins">
                <el-select
                  v-model="inputs.plugins"
                  filterable
                  multiple
                  placeholder="Install Popular Plugins"
                >
                  <el-option
                    v-for="plugin in pluginOptions"
                    :key="plugin.value"
                    :label="plugin.label"
                    :value="plugin.value"
                  >
                  </el-option>
                </el-select>
              </base-input>
              <div
                v-if="errors.get('plugins')"
                class="invalid-feedback"
                :style="{ display: errors.get('plugins') ? 'block' : 'none' }"
              >
                {{ errors.get("plugins") }}
              </div>
            </div>
          </div>

          <base-button
            type="primary"
            native-type="submit"
            @click.prevent="onSubmit"
            :loading="isLoading"
            >Submit</base-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import _ from "lodash";
import Errors from "@/utils/error";

export default {
  name: "AddBlogNetwork",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      inputs: {
        domain: "",
        network: null,
        title: "",
        theme: "",
        plugins: [],
      },
      errors: new Errors(),
      errText: "",
      submitSuccess: false,
      isLoading: false,
      networks: [],
      pluginOptions: [
        { value: "akismet", label: "Akismet" },
        { value: "all-in-one-seo-pack", label: "All in One SEO Pack" },
        { value: "antispam-bee", label: "Antispam Bee" },
        { value: "broken-link-checker", label: "Broken Link Checker" },
        { value: "classic-editor", label: "Classic Editor" },
        { value: "cms-commander-client", label: "CMS Commander Client" },
        { value: "contact-form-7", label: "Contact Form 7" },
        { value: "disable-google-fonts", label: "Disable Google Fonts" },
        { value: "imsanity", label: "Imsanity" },
        { value: "kafkai", label: "Kafkai" },
        { value: "mainwp-child", label: "MainWP Child" },
        { value: "spiderblocker", label: "SpiderBlocker" },
        { value: "wpremote", label: "The WP Remote" },
      ],
      themeOptions: [
        { value: "twentynineteen", label: "Twenty Nineteen" },
        { value: "twentyseventeen", label: "Twenty Seventeen" },
        { value: "twentyfifteen", label: "Twenty Fifteen" },
        { value: "twentyfourteen", label: "Twenty Fourteen" },
        { value: "twentythirteen", label: "Twenty Thirteen" },
        { value: "twentytwelve", label: "Twenty Twelve" },
        { value: "twentyeleven", label: "Twenty Eleven" },
        { value: "twentyten", label: "Twenty Ten" },
        { value: "sparkling", label: "Sparkling" },
        { value: "accelerate", label: "Accelerate" },
        { value: "esteem", label: "Esteem" },
        { value: "base-wp", label: "Base-wp" },
        { value: "origami", label: "Origami" },
        { value: "wilson", label: "Wilson" },
        { value: "academica", label: "Academica" },
        { value: "arke", label: "Arke" },
        { value: "ashe", label: "Ashe" },
        { value: "doo", label: "Doo" },
        { value: "ephemeris", label: "Ephemeris" },
        { value: "gist", label: "Gist" },
        { value: "hemingway", label: "Hemingway" },
        { value: "hoffman", label: "Hoffman" },
        { value: "lalita", label: "Lalita" },
        { value: "minimer", label: "Minimer" },
        { value: "lovecraft", label: "Lovecraft" },
        { value: "neve", label: "Neve" },
        { value: "rams", label: "Rams" },
        { value: "shuttle", label: "Shuttle" },
        { value: "wellington", label: "Wellington" },
        { value: "writee", label: "Writee" },
        { value: "go", label: "Go" },
        { value: "esfahan", label: "Esfahan" },
        { value: "twentytwenty", label: "Twenty Twenty" },
        { value: "harrison", label: "Harrison" },
        { value: "bard", label: "Bard" },
        { value: "total", label: "Total" },
        { value: "cenote", label: "Cenote" },
        { value: "xsimply", label: "XSimply" },
        { value: "pen", label: "Pen" },
        { value: "miniva", label: "Miniva" },
        { value: "blogfeedly", label: "BlogFeedly" },
        { value: "himalayas", label: "Himalayas" },
        { value: "businessly", label: "Businessly" },
        { value: "rocked", label: "Rocked" },
        { value: "blog-way", label: "Blog Way" },
        { value: "flash-blog", label: "Flash Blog" },
        { value: "iconic-one", label: "Iconic One" },
        { value: "olsen-light", label: "Olsen Light" },
        { value: "siteorigin-north", label: "SiteOrigin North" },
        { value: "swell-lite", label: "Swell Lite" },
        { value: "gucherry-blog", label: "GuCherry Blog" },
        { value: "makenzie-lite", label: "Makenzie Lite" },
        { value: "visual-composer", label: -"starter Visual Composer Starter" },
        { value: "catch-box", label: "Catch Box" },
        { value: "travel-insight", label: "Travel Insight" },
      ],
    };
  },
  created() {
    this.getNetworks();
  },
  watch: {
    "inputs.length": function (length) {
      if (length == "short" || length == "very_short") {
        this.inputs.use_section_heading = false;
      }

      if (length == "very_long") {
        this.inputs.use_section_heading = true;
        this.inputs.title = true;
      }
    },
    "inputs.title": function (title) {
      if (this.inputs.length == "long" || this.inputs.length == "medium") {
        if (title == false) {
          this.inputs.use_section_heading = false;
        }
      }
    },
    "inputs.use_section_heading": function (use_section_heading) {
      if (this.inputs.length == "long" || this.inputs.length == "medium") {
        if (use_section_heading == true) {
          this.inputs.title = true;
        }
      }
    },
  },
  computed: {
    hasErrors() {
      return !_.isEmpty(this.errors.errors);
    },
  },
  methods: {
    resetForm() {
      this.inputs = {
        domain: "",
        network: null,
        title: "",
        theme: "",
      };
    },
    onSubmit() {
      let self = this;
      this.submitSuccess = false;
      this.errors = new Errors();
      this.errText = "";
      this.isLoading = true;

      this.$store
        .dispatch("blognetworks/addBlogNetworks", self.inputs)
        .then((response) => {
          this.resetForm();
          this.submitSuccess = true;
          this.isLoading = false;

          this.$notify({
            message: response.message,
            timeout: 2000,
            icon: "ni ni-bell-55",
            type: "success",
            verticalAlign: "top",
            horizontalAlign: "right",
          });
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response.status === 422) {
            self.errors.record(err.response.data);
          }

          if (err.response.status === 503) {
            self.errText = "Tạo blog không thành công xin vui lòng thử lại!";
          } else {
            self.errText = "Thông tin nhập vào không đúng, xin thử lại!";
          }

          self.isLoading = false;
        });
    },
    async getNetworks() {
      try {
        const data = await this.$store.dispatch("blognetworks/fetchNetworks");
        this.networks = data;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped></style>
